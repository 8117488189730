@import "./fonts/Times-Roman.css";
@import "./fonts/NotoSansSymbols-Regular.css";
@import "./fonts/NotoEmoji.css";
@import "./fonts/NotoSerifDisplay-Regular.css";

body {
	margin: 0;
	width: 2448px;
	height: 4032px;
	transform-origin: 0 0;
	font-family: NotoSerifDisp, NotoSansSymbols, NotoEmoji, serif;
	font-size: 160px;
	/* background-color: #e3f2f7; */
	/* background-image: url(beige-blank-color-1001766.jpg); */
	background-size: cover;
}

img,
svg {
	mix-blend-mode: color-burn;
}

path {
	fill: #893700;
	mix-blend-mode: color-burn;
	filter: url(#dropshadow);
}

#name {
	position: absolute;
	width: 1650px;
	top: 615px;
	left: 399px;
	text-align: center;
	hyphens: auto;
	color: #933d00;
	font-weight: normal;
	word-break: break-all;
	line-height: 1.2;

	mix-blend-mode: color-burn;
	filter: url(#dropshadow);
}
